import { Header } from "../components/sections/Header";
import { ContactBox } from "../components/sections/Contact";

const Contact = () => {
  return (
    <div className="overflow-hidden flex flex-1 flex-col bg-extra-strong col text-strong">
      <Header />
      <main className="flex flex-col">
        <ContactBox />
      </main>
    </div>
  );
};

export default Contact;
