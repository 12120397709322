//External Imports
import Container from "@mui/material/Container";
import Grid2 from "@mui/material/Grid2";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import GestureIcon from "@mui/icons-material/Gesture";
import YoutubeIcon from "@mui/icons-material/YouTube";

//Internal Imports

const socialLinks = [
  {
    url: "https://github.com/tpafs",
    icon: <GitHubIcon color="primary-600" />,
    ariaLabel: "Github acct",
  },
  {
    url: "https://www.threads.net/@persiusorg",
    icon: <GestureIcon color="primary-600" />,
    ariaLabel: "Threads acct",
  },
  {
    url: "https://youtube.com/@Persius-org",
    icon: <YoutubeIcon color="primary-600" />,
    ariaLabel: "Youtube acct",
  },
  {
    url: "https://instagram.com/persiusorg",
    icon: <InstagramIcon color="primary-600" />,
    ariaLabel: "Instagram acct",
  },
];

export default function SocialLinks() {
  return (
    <Container align="center" maxWidth="sm">
      <Grid2 container justifyContent="center" direction="row" spacing={8}>
        {socialLinks.map((socialLink) => (
          <Grid2 key={socialLink.url}>
            <Link to={socialLink.url} aria-label={socialLink.ariaLabel}>
              {socialLink.icon}
            </Link>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
}
