import { DetailedHTMLProps, HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

export const Card = (
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    grayer?: boolean;
    colorBorder?: boolean; // Add the optional colorBorder flag
  }
) => {
  const { grayer = false, colorBorder = false, ...divProps } = props;

  return (
    <div
      {...divProps}
      className={twMerge(
        `relative shadow-xl flex-1 rounded-lg ${
          grayer ? "bg-strong" : "bg-extra-strong"
        } ${colorBorder ? `border-4 border-primary-600 dark:border-primary-600` : "border border-gray-300 dark:border-gray-600"}`,
        props.className
      )}
    />
  );
};
