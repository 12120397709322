import React from "react";
import Container from "@mui/material/Container";
// import { Link } from "react-router-dom";
// import { LinkButton } from "../components/LinkButton";

export default function Copyright(props) {
  return (
    <Container align="center">
    <div className="text-center text-gray-600 dark:text-gray-400" {...props}>
      <span>
        {"Copyright © Persius LLC "}
        {new Date().getFullYear()}
        {"."}
      </span>
    </div>
    </Container>
  );
}
