import { ReactNode } from "react";

import {
  CardsIcon,
  MagicIcon,
  DoorIcon,
  ShuffleIcon,
  SignalIcon,
  TalkingIcon,
} from "../svg/FeatureIcons";
import { Card } from "../components/Card";
import { Details } from "../components/Details";
import { Section } from "../components/Section";
import { Title } from "../components/Title";
import { GradientText } from "../components/GradientText";

const BlockTitle = ({ children }: { children: ReactNode }) => {
  return <h3 className="text-xl font-bold text-strong">{children}</h3>;
};

const BlockText = ({ children }: { children: ReactNode }) => {
  return <p className="text-light">{children}</p>;
};

const Block = ({ children }: { children: ReactNode }) => {
  return (
    <Card grayer className="items-center gap-1 p-6 col">
      {children}
    </Card>
  );
};

export const FeatureBlocks1 = () => {
  return (
    <Section className="gap-16 text-center">
      {/* Header */}
      <div className="gap-4 col">
        <Title size="md">What We Believe In</Title>
        <Details>
          We all deserve better healthcare. These are the bare minimum, yet
          unmet, principles we fight for everyday.
        </Details>
      </div>
      {/* Blocks */}
      <div className="grid items-start gap-6 lg:grid-cols-3">
        {/* Block 1 */}
        <Block>
          <DoorIcon />
          <BlockTitle>Access to Care</BlockTitle>
          <BlockText>
            We deserve coverage when it is guaranteed by our contracts or law.
          </BlockText>
        </Block>
        {/* Block 2 */}
        <Block>
          <CardsIcon />
          <BlockTitle>Transparency</BlockTitle>
          <BlockText>
            We deserve transparent policies, and clear rationales for denials.
          </BlockText>
        </Block>
        {/* Block 3 */}
        <Block>
          <SignalIcon />
          <BlockTitle>Accountability</BlockTitle>
          <BlockText>
            We deserve justice, accountability, and enforcement of existing law.
          </BlockText>
        </Block>
      </div>
    </Section>
  );
};

export const FeatureBlocks2 = () => {
  return (
    <Section className="gap-16 text-center" grayer>
      {/* Header */}
      <div className="gap-4 col">
        <Title size="lg" className="mb-24">
          Each year people receive over{" "}
          <GradientText className="from-primary-600 to-persius_blue-500">
            200,000,000
          </GradientText>{" "}
          denials. Less than{" "}
          <GradientText className="from-primary-600 to-persius_blue-500">
            1%
          </GradientText>{" "}
          are appealed.
        </Title>
        <Title size="md">How We Help</Title>
        <Details>
          We help patients navigate appeals, build tech to scale that work, and
          conduct data-driven research.
        </Details>
      </div>
      {/* Blocks */}
      <div className="grid items-start gap-6 lg:grid-cols-3">
        {/* Block 1 */}
        <Block>
          <TalkingIcon />
          <BlockTitle>Denial Support</BlockTitle>
          <BlockText>
            We help fight inappropriate coverage denials fiercely and
            responsibly, with human-in-the-loop AI.
          </BlockText>
        </Block>
        <Block>
          <ShuffleIcon />
          <BlockTitle>Technology</BlockTitle>
          <BlockText>
            We build people-centered AI and software to empower patients, and to
            secure access to healthcare.
          </BlockText>
        </Block>
        {/* Block 2 */}
        {/* Block 3 */}
        <Block>
          <MagicIcon />
          <BlockTitle>Evidence Building</BlockTitle>
          <BlockText>
            We conduct research and compile data to promote transparency,
            accountability, and justice.
          </BlockText>
        </Block>
      </div>
    </Section>
  );
};

export const IndependenceStatement = () => {
  return (
    <Section className="gap-2 text-center content-center max-w-4xl">
      {/* Header */}
      <div className="gap-4">
        <Details>
          <b>Financial Independence Statement:</b> Persius does not and will not
          ever accept venture capital investment, nor will we ever accept
          financial support from insurance or pharmaceutical corporations.
        </Details>
      </div>
    </Section>
  );
};
