import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

// Internal Imports
import { enrollEmail } from "../api/home.ts";

// import berlinWall from "../images/berlin_wall.jpg";
import { validateEmail } from "../utils/email_validation.js";
import { Hero } from "../components/MainHero.js";
import {
  FeatureBlocks2,
  IndependenceStatement,
} from "../components/sections/FeatureBlocks.tsx";
import { Newsletter } from "../components/sections/Newsletter.tsx";
import { Testimonials } from "../components/sections/Testimonials.tsx";
import { HomeFeatures } from "../components/sections/Features.tsx";

export default function HomePage() {
  let location = useLocation();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setEmail("");
    setError("");
    setMessage("");
  }, [location.key]);

  const submitEmail = async (_) => {
    if (!error) {
      setError("");
      setMessage("");
      try {
        const data = await enrollEmail(email);

        if (data) {
          setMessage("Email added!");
        }
      } catch (err) {
        if (err instanceof Error) {
          // handle errors thrown from frontend
          setError(err.message);
        } else {
          // handle errors thrown from backend
          setError(String(err));
        }
      }
    }
  };

  const validateMailingEmail = (e) => {
    let email = e.currentTarget.value;
    setEmail(email);
    setError("");
    if (email.length > 0) {
      if (!validateEmail(email)) {
        setError("Please enter a valid email address.");
      }
    }
  };

  return (
    <div className="overflow-hidden col text-strong">
      <main>
        <Hero />
        <FeatureBlocks2 />
        <HomeFeatures />
        <Testimonials />
        <Newsletter />
        <IndependenceStatement />
      </main>
    </div>
  );
}
