import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { twMerge } from "tailwind-merge";

export const Button = (
  props: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
) => (
  <button
    type="button"
    {...props}
    className={twMerge(
      "text-gray-950 font-bold bg-gray-50 rounded-lg hover:bg-gray-400 w-fit px-4 py-2",
      props.className
    )}
  />
);
