import { Header } from "../components/sections/Header";
import PrivacyPolicy from "../components/sections/Privacy";

const Privacy = () => {
  return (
    <div className="overflow-hidden col text-strong text-center">
      <Header />
      <main>
        <PrivacyPolicy />
      </main>
    </div>
  );
};

export default Privacy;
