import { DetailedHTMLProps, HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
import { MacBar } from "./MacBar";

type DemoProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  webmSrc: string;
  mp4Src: string;
  alt: string;
};

type DemoImageProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  imgSrc: string;
  alt: string;
  height: number;
  width: number;
};

export const Demo = (props: DemoProps) => {
  const { webmSrc, mp4Src, alt, ...divProps } = props;

  return (
    <div
      {...divProps}
      className={twMerge(
        "relative col w-full sm:w-2/3 justify-center max-w-xl max-h-lg overflow-hidden shadow-lg round-rect",
        divProps.className
      )}
    >
      <MacBar />
      <video
        autoPlay
        loop
        muted
        playsInline
        aria-label={alt}
        tabIndex={-1}
        width="100%"
        height="50px"
      >
        {/* Need both for Safari compatibility */}
        <source src={webmSrc} type="video/webm" />
        <source src={mp4Src} type="video/mp4" />
      </video>
    </div>
  );
};

export const DemoImage = (props: DemoImageProps) => {
  const { imgSrc, alt, height, width, ...divProps } = props;

  return (
    <div
      {...divProps}
      className={twMerge(
        "relative col w-full justify-center max-w-2xl overflow-hidden shadow-lg round-rect",
        divProps.className
      )}
    >
      <img src={imgSrc} alt={alt} width={width} height={height}></img>
    </div>
  );
};
