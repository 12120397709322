import { Link } from 'react-router-dom';

/**
 * A link with specific styling
 * @param props HTML props for an anchor tag
 */
export const LinkButton = (props) => {
  const { href, ...htmlProps } = props;

  return (
   <Link
      to={href} 
        className={`text-light hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg text-sm p-1`}
        {...htmlProps}
      />
  );
};
