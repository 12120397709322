//External Imports
import Box from "@mui/material/Box";
import { Header } from "./components/sections/Header.jsx";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import StickyFooter from "./components/StickyFooter.js";
import "./index.css";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
//Internal Imports
import { StyledEngineProvider } from "@mui/material/styles";

// Theme Ideas
const themeDefinitions = {
  OG: {
    spacing: 8,
    typography: {
      fontFamily: ["Merriweather"].join(","),
    },
    palette: {
      mode: "light",
      primary: {
        light: "#AE849A",
        main: "#8f5e77",
        dark: "#6F495C",
        contrastText: "#fff",
      },
      secondary: {
        light: "#81becc",
        main: "#62aebf",
        dark: "#62aebf",
        contrastText: "#fff",
      },
      background: {
        default: "#f5f5f4", //'#c4dddc'//'#9e9e9e'
        paper: "#ffff",
        modal: "#121212",
        blue_gray: "#8bc8d6",
        green_gray: "#71e3cf", //"#4e8f84",
        perseus_blue: "#81becc",
        paper2: "#edeae5", //"#eae7df" //"#e3e2df"
      },
      text: {
        primary: "#2a282a",
        light: "#F5F5F5",
        secondary: "#2E2E38",
        paper: "#2E2E38",
        dark: "#2E2E38",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm_plus: 300,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  },

  // Pallete: #ccb171, #3c4c5f, #947e41, #929ba4, #643414
  blue_yellow: {
    spacing: 8,
    typography: {
      fontFamily: ["Ubuntu"].join(","),
    },
    palette: {
      mode: "light",
      primary: {
        light: "#643414",
        main: "#ccb171",
        dark: "#643414",
        contrastText: "#fff",
      },
      secondary: {
        light: "#074d66",
        main: "#929ba4",
        dark: "#007c98",
        contrastText: "#fff",
      },
      background: {
        default: "#3c4c5f",
        paper: "#929ba4",
        modal: "#121212",
      },
      text: {
        primary: "#ccb171",
        secondary: "#643414",
        paper: "#643414",
        light: "#F5F5F5",
        dark: "",
      },
    },
  },

  // Palette: #17161B (tricorn black), #b284be (african violet), #3e385a (dewberry), #016c64  (poseidon), #01a4a5 (surfer)
  space: {
    spacing: 8,
    typography: {
      fontFamily: ["Iceland", "sans-serif"].join(","),
    },
    palette: {
      mode: "light",
      primary: {
        light: "#643414",
        main: "#b284be",
        dark: "#3e385a",
        contrastText: "#fff",
      },
      secondary: {
        light: "#074d66",
        main: "#929ba4",
        dark: "#007c98",
        contrastText: "#fff",
      },
      background: {
        default: "#17161B",
        paper: "#929ba4",
        paper_light: "#c1cedb",
        modal: "#121212",
      },
      text: {
        primary: "#b284be",
        secondary: "#3e385a",
        paper: "#17161B",
        light: "#fff",
        dark: "#b284be",
      },
    },
  },

  // Palette: #547c49, #5c4e44, #3a5526, #41342b, #882810
  earthy: {
    spacing: 8,
    typography: {
      fontFamily: ["Ubuntu", "sans-serif"].join(","),
    },
    palette: {
      mode: "light",
      primary: {
        light: "#643414",
        main: "#882810",
        dark: "#882810",
        contrastText: "#fff",
      },
      secondary: {
        light: "#074d66",
        main: "#547c49",
        dark: "#007c98",
        contrastText: "#fff",
      },
      background: {
        default: "#f5f5f4",
        paper: "#5c4e44",
        paper_light: "#c1cedb",
        modal: "#121212",
      },
      text: {
        primary: "#F5F5F5",
        secondary: "#3e385a",
        paper: "#17161B",
        light: "#fff",
        dark: "#b284be",
      },
    },
  },

  // Palette: #787a73, #427ca0, #a46c3a, #a4949c,  #a4949c
  blue_gold: {
    spacing: 8,
    typography: {
      fontFamily: ["Ubuntu"].join(","),
    },
    palette: {
      mode: "light",
      primary: {
        light: "#ff7350",
        main: "#acbc90",
        dark: "#910200",
        contrastText: "#fff",
      },
      secondary: {
        light: "#69ddfc",
        main: "#a4949c",
        dark: "#007c98",
        contrastText: "#fff",
      },
      background: {
        default: "#244a61", //"#f5f5f4", //'#c4dddc'//'#9e9e9e'
        paper: "#acbc90",
        modal: "#121212",
        blue_gray: "#8bc8d6",
        green_gray: "#71e3cf", //"#4e8f84",
      },
      text: {
        primary: "#2a282a",
        light: "#F5F5F5",
        color: "#8f5e77",
      },
    },
  },

  // Palette: 0C345F (prussian blue), 1D284C (space cadet), 31467D (dark cornflower blue),
  // 08538E (USAFA Blue), 03C0A7 (keppel)
  space2: {
    spacing: 8,
    typography: {
      fontFamily: ["Ubuntu"].join(","),
    },
    palette: {
      mode: "light",
      primary: {
        light: "#CA9668",
        main: "#a46c3a",
        dark: "#794F2A",
        contrastText: "#fff",
      },
      secondary: {
        light: "#23FBE1",
        main: "#028D7C",
        dark: "#026459",
        contrastText: "#fff",
      },
      background: {
        default: "#030A1D", //"#f5f5f4", //'#c4dddc'//'#9e9e9e'
        paper: "#BDBBD1",
        modal: "#121212",
      },
      text: {
        primary: "#EDEBF7",
        secondary: "#EDEBF7",
        paper: "#030A1D",
        light: "#F5F5F5",
      },
    },
  },
};

// WIP: 0C345F (prussian blue), 1D284C (space cadet), 31467D (dark cornflower blue),
// 08538E (USAFA Blue), 03C0A7 (keppel)

// WIP: 5E8690 (steel teal), 004A5A (midnight green eagle green), C1CED8 (beaue blue),
// 02201E (dark jungle green), 3A5C52 (feldgrau)

// WIP: 030A1D, 1D2A57, 7787C3, 03B59F, 4E73BA, 025696, BDBBD1,
// EDEBF7, 060D27, "#a46c3a"

// Set theme
var theme = createTheme(themeDefinitions["OG"]);
theme = responsiveFontSizes(theme);
function App(props) {
  let isLoggedIn = props.isLoggedIn;
  let setIsLoggedIn = props.setIsLoggedIn;
  const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box
          sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
        >
          <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
          {isLoggedIn && <Offset />}
          <Outlet />
          <div className="overflow-hidden col text-strong">
            <StickyFooter isLoggedIn={isLoggedIn} />
          </div>
        </Box>
      </ThemeProvider>
    </div>
  );
}

function GlobalCssPriorityApp(props) {
  return (
    <StyledEngineProvider injectFirst>
      <App {...props} />
    </StyledEngineProvider>
  );
}

// Exposes tailwind styling
export default GlobalCssPriorityApp;
