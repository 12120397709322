import { ScatteredSpheres } from "./svg/ScatteredSpheres";
import { Title } from "./components/Title";
import { Details } from "./components/Details";
import { Demo } from "./components/Demo";
import { Section } from "./components/Section";
import { GradientText } from "./components/GradientText";
import { Button } from "./components/Button";
import { useEffect, useState } from "react";

const Background = () => (
  <div
    className="absolute inset-0 translate-y-32 pointer-events-none dark:invert dark:brightness-90"
    aria-hidden="true"
  >
    <ScatteredSpheres />
  </div>
);

export const Hero = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Section
      gradients
      className="items-center justify-center min-h-screen 2xl:min-h-[1000px] h-fit gap-16 col md:flex-row"
    >
      <Background />
      {/* Text */}
      <div className="z-10 gap-3 text-center col md:text-left">
        <Title size="lg">
          <GradientText className="from-persius_green-600 to-persius_blue-600">
            {" "}
            Appeal Health Insurance Denials
          </GradientText>{" "}
        </Title>
        <Details>
          We help people fight inappropriate denials, access critical care, and
          promote transparency and accountability, for free.
        </Details>
        {isMobile && (
          <div className="flex-center">
            <a href="/signup" className="no-underline">
              {" "}
              <Button type="submit"> Get Help </Button>{" "}
            </a>
            <a
              href="https://buy.stripe.com/fZebJRbnafUy6WsbIJ"
              className="no-underline"
            >
              <Button type="submit">Donate</Button>{" "}
            </a>
          </div>
        )}
        {!isMobile && (
          <div className="flex gap-4">
            <a href="/signup" className="no-underline">
              {" "}
              <Button type="submit"> Get Help </Button>{" "}
            </a>
            <a
              href="https://buy.stripe.com/fZebJRbnafUy6WsbIJ"
              className="no-underline"
            >
              <Button type="submit">Donate</Button>{" "}
            </a>
          </div>
        )}
      </div>
      {/* </div> */}
      {/* Image */}
      <Demo
        webmSrc="/videos/liver_cancer_qa.webm"
        mp4Src="/videos/liver_cancer_qa.mp4"
        alt="A video showing our appeal Medical Policy QA tool."
      />
    </Section>
  );
};
