import { jwtDecode } from "jwt-decode";
import { API_BASE } from "./config";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export function getToken() {
  let token = localStorage.getItem("token");
  return token;
}

export function getAuthHeader() {
  let token = getToken();
  return {
    Authorization: `Bearer ${token}`,
  };
}

export const isAuthenticated = () => {
  const scopes = localStorage.getItem("scopes");
  if (!scopes) {
    return false;
  }
  let scope_list = scopes.split(",");
  return scope_list.includes("authenticated") ||
    scope_list.includes("staff") ||
    scope_list.includes("superuser")
    ? true
    : false;
};

/**
 * Login to backend and store JSON web token on success
 *
 * @param username
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const login = async (username: string, password: string) => {
  // Assert email and password are not empty
  if (!(username.length > 0)) {
    throw new Error("Please provide your username.");
  }
  if (!(password.length > 0)) {
    throw new Error("Please provide your password.");
  }
  const formData = new FormData();

  // OAuth2 expects form data, not JSON data
  formData.append("username", username);
  formData.append("password", password);

  let request_config: AxiosRequestConfig = {
    method: "post",
    url: [API_BASE, "token"].join("/"),
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  };

  try {
    const response: AxiosResponse = await axios(request_config);
    const data = response.data;

    if ("access_token" in data) {
      const decodedToken: any = jwtDecode(data["access_token"]);
      localStorage.setItem("token", data["access_token"]);
      localStorage.setItem("scopes", decodedToken.scopes);
    }

    return data;
  } catch (error) {
    // Axios error
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 500) {
          throw new Error("Internal server error");
        }

        if (error.response.status >= 400 && error.response.status < 500) {
          if (error.response.data.detail) {
            throw error.response.data.detail;
          }
          throw error.response.data;
        }
      }
    } else {
      throw new Error("Whoops, internal server error.");
    }
  }
};

/**
 * Sign up via backend and store JSON web token on success
 *
 * @param username
 * @param FirstName
 * @param LastName
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const signUp = async (
  username: string,
  FirstName: string,
  LastName: string,
  email: string,
  password: string,
  passwordConfirmation: string
) => {
  // Assert email or password or password confirmation is not empty
  if (!(username.length > 0)) {
    throw new Error("Username was not provided");
  }
  if (!(FirstName.length > 0)) {
    throw new Error("First name was not provided");
  }
  if (!(LastName.length > 0)) {
    throw new Error("Last name was not provided");
  }
  if (!(email.length > 0)) {
    throw new Error("Email was not provided");
  }
  if (!(password.length > 0)) {
    throw new Error("Password was not provided");
  }
  if (!(passwordConfirmation.length > 0)) {
    throw new Error("Password confirmation was not provided");
  }

  const formData = new FormData();

  // OAuth2 expects form data, not JSON data
  formData.append("username", username);
  formData.append("firstname", FirstName);
  formData.append("lastname", LastName);
  formData.append("email", email);
  formData.append("password", password);

  let request_config: AxiosRequestConfig = {
    method: "post",
    url: [API_BASE, "signup"].join("/"),
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  };

  try {
    const response: AxiosResponse = await axios(request_config);
    const data = response.data;

    return data;
  } catch (error) {
    // Axios error
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 500) {
          throw new Error("Internal server error");
        }

        if (error.response.status >= 400 && error.response.status < 500) {
          if (error.response.data.detail) {
            throw error.response.data.detail;
          }
          throw error.response.data;
        }
      }
    } else {
      throw new Error("Whoops, internal server error.");
    }
  }
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("scopes");
};

export function handle401() {
  function refreshPage() {
    window.location.reload();
  }
  // Clear expired or false token
  logout();
  refreshPage();
}

/**
 * Verify Email with code
 *
 * @param username
 * @param verificationCode
 * @returns boolean
 * @throws Error on http errors or failed attempts
 */
export const verifyEmail = async (
  username: string,
  verificationCode: string
) => {
  // Assert verification code is not empty
  if (!(verificationCode.length > 0)) {
    throw new Error("Please supply a verification code.");
  }
  const formData = new FormData();

  formData.append("username", username);
  formData.append("verification_code", verificationCode);

  let request_config: AxiosRequestConfig = {
    method: "put",
    url: [API_BASE, "verify_email"].join("/"),
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  };

  try {
    const response: AxiosResponse = await axios(request_config);
    const data = response.data;

    return data;
  } catch (error) {
    // Axios error
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 500) {
          throw new Error("Internal server error");
        }

        if (error.response.status >= 400 && error.response.status < 500) {
          if (error.response.data.detail) {
            throw error.response.data.detail;
          }
          throw error.response.data;
        }
      }
    } else {
      throw new Error("Whoops, internal server error.");
    }
  }
};

/**
 * Generate email verification code
 *
 * @param username
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const generateVerificationCode = async (username: string) => {
  const formData = new FormData();

  formData.append("username", username);

  let request_config: AxiosRequestConfig = {
    method: "put",
    url: [API_BASE, "generate_verification_code"].join("/"),
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  };

  try {
    const response: AxiosResponse = await axios(request_config);
    const data = response.data;

    return data;
  } catch (error) {
    // Axios error
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 500) {
          throw new Error("Internal server error");
        }

        if (error.response.status >= 400 && error.response.status < 500) {
          if (error.response.data.detail) {
            throw error.response.data.detail;
          }
          throw error.response.data;
        }
      }
    } else {
      throw new Error("Whoops, internal server error.");
    }
  }
};
