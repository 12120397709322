// External Imports
import { Details } from "../components/Details";
import { Section } from "../components/Section";

// Internal Imports

export default function PrivacyPolicy() {
  return (
    <Section className="gap-16 text-center">
      <div className="z-10 gap-4 text-center col md:text-left">
        <Details className="font-extrabold">PRIVACY POLICY</Details>
        <Details>
          {" "}
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from
          https://persius.org (the “Site”).
        </Details>
        <Details>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from
          https://persius.org (the “Site”).
        </Details>
        {/* Section */}
        <Details className="font-extrabold">
          PERSONAL INFORMATION WE COLLECT
        </Details>
        <Details>
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this
          automatically-collected information as “Device Information.” We
          collect Device Information using the following technologies:
          <br /> <br /> - “Cookies” are data files that are placed on your
          device or computer and often include an anonymous unique identifier.
          For more information about cookies, and how to disable cookies, visit
          https://en.wikipedia.org/wiki/HTTP_cookie.
          <br /> - “Log files” track actions occurring on the Site, and collect
          data including your IP address, browser type, Internet service
          provider, referring/exit pages, and date/time stamps.
          <br /> - “Web beacons,” “tags,” and “pixels” are electronic files used
          to record information about how you browse the Site.
          <br /> - "Local Storage" is storage on your device where we may
          temporarily save certain data that allows the Site to understand your
          intentions and operational state as a user (e.g. are you logged in).
          Similar to cookies.
          <br />
          <br />
          Additionally when you make a purchase or attempt to make a purchase
          through the Site, we collect certain information from you, including
          your name, billing address, shipping address, payment information
          (including credit card numbers ), email address, and phone number. We
          refer to this information as “Order Information.” Similarly, when you
          use our denied claims process, add your health insurance coverage to
          your profile, or generally provide information related to your health
          or health coverage, we collect certain information from you such as
          your insurers name, plan name, member ID, and complaints and reviews.
          We refer to this information as "Insurance and Health Information".
          When we talk about “Personal Information” in this Privacy Policy, we
          are talking about Device Information, Insurance and Health Information
          and Order Information.
        </Details>
        <Details className="font-extrabold">
          HOW DO WE USE YOUR PERSONAL INFORMATION?
        </Details>
        <Details>
          We use the Order Information that we collect generally to fulfill any
          orders placed through the Site (including processing your payment
          information, arranging for shipping, and providing you with invoices
          and/or order confirmations). Additionally, we use this Order
          Information to: Communicate with you; Screen our orders for potential
          risk or fraud; and When in line with the preferences you have shared
          with us, provide you with information or advertising relating to our
          products or services. We use the Device Information that we collect to
          help us screen for potential risk and fraud (in particular, your IP
          address), and more generally to improve and optimize our Site (for
          example, by generating analytics about how our customers browse and
          interact with the Site, and to assess the success of our marketing and
          advertising campaigns). We use the Insurance and Health Information
          that we collect to facilitate the services that we explicitly provide
          through user accounts, including showcasing user reviews to the
          community, allowing users to provide ratings for their insurance
          policies, and aiding users with claim resolution help that
          necessitates access to such information.
        </Details>
        <Details className="font-extrabold">
          SHARING YOUR PERSONAL INFORMATION
        </Details>
        <Details>
          We will only share your Personal Information to comply with applicable
          laws and regulations, to respond to a subpoena, search warrant or
          other lawful request for information we receive, or to otherwise
          protect our rights. Except for these methods, we currently do not
          share your Personal Information in any other capacity.
        </Details>
        <Details className="font-extrabold">DO NOT TRACK</Details>
        <Details>
          Please note that we currently do not alter our Site's data collection
          and use practices when we see a Do Not Track signal from your browser.
          This is because currently we do not track data collected from your
          browser in any significant way; all ways in which we track data are
          explicitly mentioned above, and most are completely necessary for the
          functionality of the Site.
        </Details>
        <Details className="font-extrabold">YOUR RIGHTS</Details>
        <Details>
          If you are a European resident, you have the legal right to access
          personal information we hold about you and to ask that your personal
          information be corrected, updated, or deleted. If you would like to
          exercise this right, please contact us through the contact information
          below. This Site is obviously most useful for U.S. residents, and we
          are happy to honor this right for U.S. residents as well.
          Additionally, if you are a European resident we note that we are
          processing your information in order to fulfill contracts we might
          have with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
          Additionally, please note that your information will be transferred
          outside of Europe, including to Canada and the United States.
        </Details>
        <Details className="font-extrabold">DATA RETENTION</Details>
        <Details>
          When you place an order through the Site, or use services that involve
          inputting your Insurance and Health Information to the Site, we will
          maintain your Order Information and Insurance and Health Information
          for our records unless and until you ask us to delete this
          information.
        </Details>
        <Details className="font-extrabold">MINORS</Details>
        <Details>
          The Site is not intended for individuals under the age of 18.
        </Details>
        <Details className="font-extrabold">CHANGES</Details>
        <Details>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </Details>
        <Details className="font-extrabold">CONTACT US</Details>
        <Details>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at info@persius.org
          {/* or by mail using the details provided below: Philadelphia, PA, United States */}
        </Details>
      </div>
    </Section>
  );
}
