import { NewsletterSpheres } from "../svg/NewsletterSpheres";
import { Card } from "../components/Card";
import { Section } from "../components/Section";
import { GradientText } from "../components/GradientText";
const Background = () => (
  <div
    className="absolute bottom-0 right-0 hidden pointer-events-none md:block"
    aria-hidden="true"
  >
    <NewsletterSpheres />
  </div>
);

export const ContactBox = () => {
  return (
    <Section className="px-4 mt-20 dark sm:px-12">
      <Card className="w-full px-4 py-16 overflow-hidden sm:px-16">
        <Background />
        <div className="gap-6 text-center md:text-left col md:w-1/2 ">
          <h2 className="text-3xl font-bold text-gray-100">
            <GradientText className="from-persius_green-600 to-persius_blue-500">
              Get In Touch
            </GradientText>
          </h2>
          <p className="text-lg text-light">
            We're here to help, and happy to answer any questions you might
            have. Feel free to reach out by email at{" "}
            <a
              href="mailto:info@persius.org"
              className="underline text-primary-400"
            >
              info@persius.org
            </a>{" "}
            any time.
          </p>

          <p className="text-lg text-light">
            {" "}
            We'll do our best to answer as soon as possible.
          </p>
          <p className="text-lg text-light">
            Thank you, <br /> The Persius Team
          </p>
        </div>
      </Card>
    </Section>
  );
};
