import { useState, useEffect } from "react";
import { Section } from "../components/Section";
import { GradientText } from "../components/GradientText";
import { useNavigate } from "react-router-dom";

import {
  getDigitalDownloads,
  incrementDownloadViewCount,
} from "../../api/resources.ts";

function openInNewTab(url) {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
}

export default function DownloadTable() {
  const [error, setError] = useState(null);
  const [resources, setResources] = useState([]);
  const [areResourcesLoaded, setAreResourcesLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getDigitalDownloads()
      .then((result) => {
        setResources(result);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setAreResourcesLoaded(true));
  }, []);

  return (
    <Section className="gap-16 text-center mt-20">
      <div className="max-w-6xl mx-auto p-4">
        <h2 className="text-2xl font-bold text-center mb-6">
          <GradientText className="from-persius_blue-600 to-persius_blue-500">
            Digital Downloads
          </GradientText>
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse border border-gray-400 rounded-lg">
            <thead className="hidden md:table-header-group">
              <tr className="bg-gray-800 text-light">
                <th className="py-2 px-4 border-b">Resource Name</th>
                <th className="py-2 px-4 border-b">Description</th>
                <th className="py-2 px-4 border-b">Version</th>
                <th className="py-2 px-4 border-b">Release Date</th>
                <th className="py-2 px-4 border-b">Last Modified</th>
                <th className="py-2 px-4 border-b">Views</th>
                <th className="py-2 px-4 border-b">Link</th>
              </tr>
            </thead>
            <tbody>
              {resources.map((resource, index) => (
                <tr
                  key={index}
                  className="bg-white text-light block md:table-row mb-6 md:mb-0 border md:border-none"
                >
                  <td className="py-2 px-4 border-b block md:table-cell text-center font-bold">
                    {resource.name}
                  </td>
                  <td className="py-2 px-4 border-b block md:table-cell">
                    {resource.description}
                  </td>
                  <td className="py-2 px-4 border-b block md:table-cell text-center">
                    {resource.version}
                  </td>
                  <td className="py-2 px-4 border-b block md:table-cell text-center">
                    {resource.release_date}
                  </td>
                  <td className="py-2 px-4 border-b block md:table-cell text-center">
                    {resource.last_modified_date}
                  </td>
                  <td className="py-2 px-4 border-b block md:table-cell text-center">
                    {resource.views}
                  </td>
                  <td className="py-2 px-4 border-b block md:table-cell text-center">
                    <button
                      className="text-primary-400 underline"
                      onClick={(e) => {
                        e.preventDefault();
                        incrementDownloadViewCount(resource.uid);
                        openInNewTab(resource.link);
                        navigate(0);
                      }}
                    >
                      Download
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Section>
  );
}
