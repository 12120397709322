import { MissionFeatures } from "../components/sections/Features";
import { Header } from "../components/sections/Header";
import { MissionHero } from "../components/sections/Hero";

const Mission = () => {
  return (
    <div className="overflow-hidden col text-strong">
      <Header />
      <main>
        <MissionHero />
        <MissionFeatures />
      </main>
    </div>
  );
};

export default Mission;
