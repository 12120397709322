import { DetailedHTMLProps, FormEvent, HTMLAttributes, useState } from "react";
import { twMerge } from "tailwind-merge";

import { Button } from "./Button";
import { Input } from "./Input";
import { enrollEmail } from "../../api/home";
import { validateEmail } from "../../utils/email_validation.js";

interface EnrollEmailResponse {
  email_address: string;
}

export const NewsletterForm = (
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    id: string;
  }
) => {
  const { id, ...divProps } = props;

  const [email, setEmail] = useState("");
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const submitEmail = async (
    _: any
  ): Promise<EnrollEmailResponse | undefined> => {
    let data: EnrollEmailResponse | undefined;

    setError("");
    setMessage("");
    try {
      data = await enrollEmail(email);
    } catch (err) {
      if (err instanceof Error) {
        // Handle errors thrown from frontend
        setError(err.message);
      } else {
        // Handle errors thrown from backend
        setError(String(err));
      }
    }

    return data;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email.length > 0) {
      if (!validateEmail(email)) {
        setError("Please enter a valid email address.");
      } else {
        const res = await submitEmail(email);
        setEmail("");
        if (res?.email_address) {
          setEmailSubmitted(true);
          setMessage("We'll keep you posted!");
        } else {
          setEmailSubmitted(true);
          setMessage(
            "There was an error adding your email to the list. Please try again, and contact us if the problem persists."
          );
        }
      }
    }
  };

  const EmailMessage = () => (
    <div>
      <p className="mt-3 text-sm text-light">{message}</p>
    </div>
  );

  return (
    <div {...divProps} className={twMerge("col", divProps.className)}>
      <form className="relative gap-2 md:gap-4 row" onSubmit={handleSubmit}>
        <Input
          type="email"
          className="w-full"
          placeholder="info@persius.org"
          aria-label="Your email"
          name="email"
          id={id}
          onChange={(event) => {
            setEmail(event.target.value);
            setMessage("");
          }}
          value={email}
          required
        />
        <label
          htmlFor={id}
          className="absolute z-10 text-xs -top-2 left-4 text-light"
        >
          Your Email
        </label>
        {/* Line beneath label to hide outline */}
        <div className="absolute top-0 w-[4.3rem] h-[2px] bg-strong left-3" />
        <Button type="submit">Join</Button>{" "}
      </form>
      <EmailMessage />
    </div>
  );
};
