import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid2 from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { createSearchParams } from "react-router-dom";
import { Alert } from "@mui/material";

// Internal Imports
import { signUp } from "../utils/auth.ts";
import { validateEmail } from "../utils/email_validation.js";

import { styled } from '@mui/material/styles';


const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiInput-base:placeholder': {
    color: 'red',
    textColor: 'red'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
  },
});

export default function Signup(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState("");

  let isLoggedIn = props.isLoggedIn;
  let setIsLoggedIn = props.setIsLoggedIn;

  const handleSubmit = async (_) => {
    // Password confirmation validation
    if (password !== passwordConfirmation) setError("Passwords do not match");
    else {
      setError("");
      if (!validateEmail(email)) {
        if (email.length > 0) setError("Please enter a valid email address.");
      } else {
        try {
          const data = await signUp(
            username,
            FirstName,
            LastName,
            email,
            password,
            passwordConfirmation
          );

          if (data) {
            navigate({
              pathname: "/verify-email",
              search: createSearchParams({
                username: username,
              }).toString(),
            });
          }
        } catch (err) {
          if (err instanceof Error) {
            // handle errors thrown from frontend
            setError(err.message);
          } else {
            // handle errors thrown from backend
            setError(String(err));
          }
        }
      }
    }
  };

  return isLoggedIn ? (
    <Navigate to="/" />
  ) : (
    <Container
      component="main"

      maxWidth="100%"
      className="bg-extra-strong"
      sx={{
        flex: 1, // Allows Container to grow within the Box
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Center content vertically
        alignItems: "center",
      }}
    >
      <Container maxWidth="xs">
      {/* <CssBaseline /> */}
      <Box
        sx={{
          marginTop: 16,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className="text-light">
          Sign Up
        </Typography>
        <Box noValidate sx={{ mt: 3 }}>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <CssTextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                value={FirstName}
                onChange={(e) => setFirstName(e.currentTarget.value)}
                id="firstName"
                label="First Name"
                autoFocus
                sx={{ input: { color: '#E0E3E7' } }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <CssTextField
                required
                fullWidth
                value={LastName}
                onChange={(e) => setLastName(e.currentTarget.value)}
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                sx={{ input: { color: '#E0E3E7' } }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <CssTextField
                required
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                sx={{ input: { color: '#E0E3E7' } }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <CssTextField
                required
                fullWidth
                id="username"
                value={username}
                onChange={(e) => setUsername(e.currentTarget.value)}
                label="Username"
                name="username"
                autoComplete="username"
                sx={{ input: { color: '#E0E3E7' } }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <CssTextField
                required
                fullWidth
                name="password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                sx={{ input: { color: '#E0E3E7' } }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <CssTextField
                required
                fullWidth
                name="passwordConfirmation"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.currentTarget.value)}
                label="Confirm Password"
                type="password"
                id="passwordConfirmation"
                sx={{ input: { color: '#E0E3E7' } }}
              />
            </Grid2>
            {/* <Grid2 item size={{ xs: 12 }}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive updates via email."
              />
            </Grid2> */}
          </Grid2>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid2 container justifyContent="flex-end">
            <Grid2>
              <Link component={RouterLink} to={"/login"} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid2>
          </Grid2>
          <Grid2 container mt={3} justifyContent="center">
            {error && (
              <Grid2 justifyContent="center">
                <Alert severity="error">{error}</Alert>
              </Grid2>
            )}
          </Grid2>
        </Box>
      </Box>
      </Container>
    </Container>
  );
}
