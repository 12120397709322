import MacBarSrc from "../images/mac-bar.png";

export const MacBar = () => {
  // Need rounding here too for Safari
  return (
    <img
      src={MacBarSrc}
      width="100%"
      height="auto"
      className="round-rect-top"
      alt="Mac window bar"
    />
  );
};
