// External imports
import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid2 from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Alert } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Internal Imports
import { generateVerificationCode, verifyEmail } from "../utils/auth.ts";

import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiInput-base:placeholder": {
    color: "red",
    textColor: "red",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
  },
});

export default function VerifyEmail() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let username = searchParams.get("username");
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);

  const handleSubmit = async (_) => {
    setError("");
    setMessage("");
    try {
      const verificationResponse = await verifyEmail(
        username,
        verificationCode
      );

      if (verificationResponse.verified === true) {
        navigate("/login");
      } else if (verificationResponse.verified) {
        setMessage(verificationResponse.detail);
      } else {
        setError("Network error.");
      }
    } catch (err) {
      if (err instanceof Error) {
        // handle errors thrown from frontend
        setError(err.message);
      } else {
        // handle errors thrown from backend
        setError(String(err));
      }
    }
  };

  const handleGenerate = async (_) => {
    setError("");
    setMessage("");
    try {
      const generationResponse = await generateVerificationCode(username);

      if (generationResponse) {
        setMessage(
          "New verification code generated.\n It is valid for 10 minutes."
        );
      } else {
        setError("Network error.");
      }
    } catch (err) {
      if (err instanceof Error) {
        // handle errors thrown from frontend
        setError(err.message);
      } else {
        // handle errors thrown from backend
        setError(String(err));
      }
    }
  };

  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="100%"
        className="bg-extra-strong"
        sx={{
          flex: 1, // Allows Container to grow within the Box
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // Center content vertically
          alignItems: "center",
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" className="text-light">
              Email Verification
            </Typography>
            <Box noValidate sx={{ mt: 1 }}>
              <Typography
                align="center"
                sx={{ mt: 2 }}
                component="p"
                variant="p"
                className="text-light"
              >
                Click 'Generate Verification Code' to generate a one time code
                that will be sent to your email.
              </Typography>
              <Button
                type="submit"
                fullWidth
                size="small"
                onClick={handleGenerate}
                sx={{ mt: 3, mb: 2 }}
              >
                Generate Verification Code
              </Button>
            </Box>
            <Box noValidate sx={{ mt: 1 }}>
              <CssTextField
                margin="normal"
                required
                fullWidth
                id="verification_code"
                label="Verification Code"
                name="verification_code"
                onChange={(e) => setVerificationCode(e.currentTarget.value)}
                autoComplete="verification_code"
                autoFocus
                sx={{ input: { color: "#E0E3E7" } }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                sx={{ mt: 3, mb: 2 }}
              >
                Verify Email
              </Button>
            </Box>
            <Grid2 container mt={3} justifyContent="center">
              {error && (
                <Grid2 justifyContent="center">
                  <Alert severity="error">{error}</Alert>
                </Grid2>
              )}
            </Grid2>
            <Grid2 container mt={3} justifyContent="center">
              {message && (
                <Grid2 justifyContent="center">
                  <Alert severity="info">{message}</Alert>
                </Grid2>
              )}
            </Grid2>
          </Box>
        </Container>
      </Container>
    </React.Fragment>
  );
}
