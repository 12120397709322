// External Imports
import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { Alert } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs as DateAdapter } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// Internal Imports
import { AddInsurerModal, AddPlanModal } from "../Modals";
import { handle401 } from "../../utils/auth.ts";
import { createCoverage, editCoverage } from "../../api/accounts.ts";
import {
  getRegions,
  getInsurerPlans,
  getRegionInsurers,
} from "../../api/resources.ts";

// Capitalize utility
function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const EditCoverage = (props) => {
  const [regions, setRegions] = useState([]);
  const [areRegionsLoaded, setAreRegionsLoaded] = useState(false);
  const [insurers, setInsurers] = useState([]);
  const [plans, setPlans] = useState([]);
  const [error, setError] = useState(null);
  const [userLoaded, setUserLoaded] = useState(false);
  const [validationError, setValidationError] = useState({
    field: "",
    message: "",
  });
  const [editable, setEditable] = useState(false);

  let user = props.user;
  let coverage = props.coverage;
  let setCoverageCurrent = props.setCoverageCurrent;

  // TODO: Use alias to allow for converting to/from camelCase
  const defaultValues = {
    region: "",
    insurer: "",
    plan: "",
    member_id: "",
    effective_date: dayjs(),
    premium_cost: "",
    user_cost: "",
    employer_sponsored: "",
  };

  const [values, setValues] = useState(defaultValues);

  // Verify whether user has been loaded
  useEffect(() => {
    if (user.username !== undefined) {
      setUserLoaded(true);
    }
  }, [user]);

  function refreshCoverage() {
    let newValues = defaultValues;

    if (coverage.uid) {
      if (coverage?.region?.name) {
        newValues.region = coverage.region.name;
      }
      if (coverage?.insurer) {
        newValues.insurer = coverage.insurer.display_name;
      }
      if (coverage?.plan) {
        newValues.plan = coverage.plan.display_name;
      }
      if (coverage?.member_id) {
        newValues.member_id = coverage.member_id;
      }
      newValues.effective_date = dayjs(coverage.effective_date);
      if (coverage?.premium_cost) {
        newValues.premium_cost = coverage.premium_cost;
      }
      if (coverage?.user_cost) {
        newValues.user_cost = coverage.user_cost;
      }
      if (coverage?.employer_sponsored != null) {
        newValues.employer_sponsored = capitalize(
          coverage.employer_sponsored.toString()
        );
      }
    }

    setValues(newValues);
  }

  useEffect(refreshCoverage, [coverage]);

  const updateRegion = (e) => {
    setError(null);
    setValidationError({
      field: "",
      message: "",
    });
    setValues({
      ...values,
      region: e.target.value,
      insurer: "",
      plan: "",
    });
    setInsurers([]);
    setPlans([]);
  };

  const updateInsurer = (e) => {
    setError(null);
    setValidationError({
      field: "",
      message: "",
    });
    setValues({
      ...values,
      insurer: e.target.value,
      plan: "",
    });
    setPlans([]);
  };

  const updatePlan = (e) => {
    setError(null);
    setValidationError({
      field: "",
      message: "",
    });
    setValues({
      ...values,
      plan: e.target.value,
    });
  };

  const updateMemberID = (e) => {
    setError(null);
    setValidationError({
      field: "",
      message: "",
    });
    setValues({
      ...values,
      member_id: e.target.value,
    });
  };

  const updatePremiumCost = (e) => {
    setError(null);
    setValidationError({
      field: "",
      message: "",
    });
    setValues({
      ...values,
      premium_cost: e.target.value,
    });
  };

  const updateUserCost = (e) => {
    setError(null);
    setValidationError({
      field: "",
      message: "",
    });
    setValues({
      ...values,
      user_cost: e.target.value,
    });
  };

  const updateEmployerSponsored = (e) => {
    setError(null);
    setValidationError({
      field: "",
      message: "",
    });
    setValues({
      ...values,
      employer_sponsored: e.target.value,
    });
  };

  const updateEffectiveDate = (e) => {
    setError(null);
    setValidationError({
      field: "",
      message: "",
    });
    setValues({
      ...values,
      effective_date: e,
    });
  };

  // TODO: Add some more legit validation
  function validCoverage(values) {
    if (values.region.length === 0) {
      setValidationError({
        field: "region",
        message: "Region required.",
      });
    } else if (values.insurer.length === 0) {
      setValidationError({
        field: "insurer",
        message:
          "Insurer Required. Please create a new insurer if yours is not an option here.",
      });
    } else if (values.plan.length === 0) {
      setValidationError({
        field: "plan",
        message:
          "Plan Required. Please create a new plan if yours is not an option here.",
      });
    } else if (values.member_id.length === 0) {
      setValidationError({
        field: "member_id",
        message: "Member ID Required.",
      });
    } else if (!values.effective_date) {
      setValidationError({
        field: "effective_date",
        message: "Effective start date required.",
      });
    } else if (
      !Number.isInteger(parseInt(values.premium_cost)) &&
      values?.premium_cost?.length !== 0
    ) {
      // TODO: Filter decimals
      setValidationError({
        field: "premium_cost",
        message: "Premium must be an integer if entered.",
      });
    } else if (
      !Number.isInteger(parseInt(values.user_cost)) &&
      values?.user_cost?.length !== 0
    ) {
      // TODO: Filter decimals
      setValidationError({
        field: "user_cost",
        message: "Out of Pocket cost must be an integer if entered.",
      });
    } else {
      if (values?.premium_cost?.length !== 0) {
        // TODO: whoops, use setter here for state!
        values.premium_cost = parseInt(values.premium_cost);
      }
      if (values?.user_cost?.length !== 0) {
        values.user_cost = parseInt(values.user_cost);
      }
      return true;
    }
    return false;
  }

  function refreshRegions() {
    getRegions()
      .then((result) => {
        if (result.data) {
          setRegions(result.data);
        } else {
          setError({ message: "Network error" });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handle401();
        } else {
          setError(error);
        }
      })
      .finally(() => setAreRegionsLoaded(true));
  }

  function refreshInsurers() {
    let region = values.region;
    if (region) {
      getRegionInsurers(region)
        .then((result) => {
          if (result?.data?.length > 0) {
            setInsurers(result.data);
          } else {
            setInsurers([]);
            setValues({ ...values, insurer: "" });
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            handle401();
          } else {
            setError(error);
          }
        });
    }
  }

  function refreshPlans() {
    let region = values.region;
    let insurer = values.insurer;
    if (region && insurer) {
      let params = new URLSearchParams({
        insurer_name: insurer,
        region: region,
      });
      getInsurerPlans(params)
        .then((result) => {
          if (result?.data?.plans?.length > 0) {
            setPlans(result.data.plans);
          } else {
            setPlans([]);
            setValues({ ...values, plan: "" });
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            handle401();
          } else {
            setError(error);
          }
        });
    }
  }

  useEffect(refreshRegions, []);
  useEffect(refreshInsurers, [values.region]);
  useEffect(refreshPlans, [values.region, values.insurer]);

  const handleSubmit = () => {
    let valid = validCoverage(values);
    if (valid) {
      // Only submit non-empty entries
      const submittable = Object.fromEntries(
        Object.entries(values).filter(([key]) => values[key] !== "")
      );
      submittable.effective_date = dayjs(submittable.effective_date).format(
        "YYYY-MM-DD"
      );

      if (coverage?.uid) {
        submittable.uid = coverage.uid;
        editCoverage(submittable);
      } else {
        createCoverage(submittable);
      }
      setEditable(false);
      setCoverageCurrent(false);
    }
  };

  const handleEdit = () => {
    setEditable(true);
  };

  const handleCancel = () => {
    refreshCoverage();
    setEditable(false);
  };

  if (areRegionsLoaded && userLoaded) {
    if (!error) {
      return (
        <form autoComplete="off" noValidate>
          <Card>
            <CardHeader
              subheader=""
              title="Current Coverage Overview"
              titleTypographyProps={{ align: "center" }}
              subheaderTypographyProps={{
                align: "center",
              }}
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[200]
                    : theme.palette.grey[700],
              }}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="First name"
                    name="first_name"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={capitalize(user.first_name)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Last name"
                    InputProps={{
                      readOnly: true,
                    }}
                    name="last_name"
                    value={capitalize(user.last_name)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{
                      readOnly: !editable,
                    }}
                    label="State or Region"
                    name="region"
                    error={validationError.field === "region"}
                    helperText={
                      validationError.field === "region" &&
                      validationError.message
                    }
                    InputLabelProps={{ shrink: true }}
                    onChange={updateRegion}
                    required
                    select={editable}
                    SelectProps={{ native: true }}
                    value={values.region}
                    variant="outlined"
                  >
                    <option key="no_region" value="" disabled>
                      Select a Region
                    </option>
                    {regions.map((option) => (
                      <option key={option.uid} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={validationError.field === "member_id"}
                    helperText={
                      validationError.field === "member_id" &&
                      validationError.message
                    }
                    label="Member ID"
                    name="member_id"
                    InputProps={{
                      readOnly: !editable,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={updateMemberID}
                    required
                    value={values.member_id}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Insurer"
                    name="insurer"
                    error={validationError.field === "insurer"}
                    helperText={
                      validationError.field === "insurer" &&
                      validationError.message
                    }
                    onChange={updateInsurer}
                    required
                    select={editable}
                    InputProps={{
                      readOnly: !editable,
                    }}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    value={values.insurer}
                    variant="outlined"
                  >
                    <option key="no_insurers_logged" value="" disabled>
                      Select an Insurer
                    </option>
                    {insurers.map((option) => (
                      <option key={option.uid} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                  <AddInsurerModal closeAction={refreshRegions} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Plan"
                    name="plan"
                    error={validationError.field === "plan"}
                    helperText={
                      validationError.field === "plan" &&
                      validationError.message
                    }
                    onChange={updatePlan}
                    required
                    select={editable}
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    value={values.plan}
                    variant="outlined"
                  >
                    <option key="no_plans_logged" value="" disabled>
                      Select a Plan
                    </option>
                    {plans.map((option) => (
                      <option key={option.uid} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                  <AddPlanModal closeAction={refreshRegions} />
                </Grid>
                <Grid item s={6} xs={12} md={3}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="Effective Date of Coverage"
                      value={values.effective_date}
                      readOnly={!editable}
                      onChange={updateEffectiveDate}
                      renderInput={(params) => (
                        <TextField
                          error={validationError.field === "effective_date"}
                          helperText={
                            validationError.field === "effective_date" &&
                            validationError.message
                          }
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item md={3} s={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Out of Pocket Cost"
                    InputProps={{
                      readOnly: !editable,
                    }}
                    error={validationError.field === "user_cost"}
                    helperText={
                      validationError.field === "user_cost" &&
                      validationError.message
                    }
                    onChange={updateUserCost}
                    name="user_cost"
                    value={values.user_cost}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} s={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Full Premium"
                    InputProps={{
                      readOnly: !editable,
                    }}
                    error={validationError.field === "premium_cost"}
                    helperText={
                      validationError.field === "premium_cost" &&
                      validationError.message
                    }
                    onChange={updatePremiumCost}
                    name="premium_cost"
                    value={values.premium_cost}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} s={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Employer Sponsored Plan"
                    name="employer_sponsored"
                    onChange={updateEmployerSponsored}
                    select={editable}
                    InputProps={{
                      readOnly: !editable,
                    }}
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    value={values.employer_sponsored}
                    variant="outlined"
                  >
                    <option key="no_choice" value="" disabled>
                      Select an Option
                    </option>
                    <option key="true" value="True">
                      True
                    </option>
                    <option key="false" value="False">
                      False
                    </option>
                  </TextField>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2,
              }}
            >
              {editable && (
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                >
                  <Grid item>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Save details
                    </Button>
                  </Grid>
                </Grid>
              )}
              {!editable && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleEdit}
                >
                  Update Info
                </Button>
              )}
            </Box>
          </Card>
        </form>
      );
    } else {
      return (
        <Card>
          <CardContent>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Alert severity="error">{error}</Alert>
            </Box>
          </CardContent>
          <Divider />
        </Card>
      );
    }
  } else {
    return (
      <Card>
        <CardContent>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CircularProgress />
          </Box>
        </CardContent>
        <Divider />
      </Card>
    );
  }
};
