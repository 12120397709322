import TermsOfService from "../components/sections/Terms";
import { Header } from "../components/sections/Header";

const Terms = () => {
  return (
    <div className="overflow-hidden col text-strong">
      <Header />
      <main>
        <TermsOfService />
      </main>
    </div>
  );
};

export default Terms;
