import { LinkButton } from "../components/LinkButton";
import Copyright from "../components/Copyright";
import SocialLinks from "../components/Social";

const footers = [
  {
    title: "Organization",
    descriptions: [
      "Mission",
      "Contact Us",
      "Donate ❤️",
      "Shop",
      "Join Us on Discord",
    ],
    links: [
      "/about",
      "/contact",
      "https://buy.stripe.com/fZebJRbnafUy6WsbIJ",
      "https://store.persius.org/",
      "https://discord.gg/QmvHWhtmK4",
      // "https://store.persius.org",
    ],
  },
  {
    title: "Legal",
    descriptions: ["Privacy policy", "Terms of use"],
    links: ["/privacy", "/terms"],
  },
  {
    title: "Resources",
    descriptions: [
      "Blog",
      "Digital Downloads",
      "Research",
      "Appeal Overturn Predictor",
      "Public Records",
      "Transparency Data",
    ],
    links: [
      "https://blog.persius.org",
      "/resources/downloads",
      "https://blog.persius.org/investigations",
      "https://overturn-predictor.persius.org/",
      "https://repos.persius.org/public-records/",
      "https://github.com/TPAFS/transparency-data",
    ],
  },
];

// Zip utility, courtesy of https://stackoverflow.com/a/22015930
const zip = (a, b) => a.map((k, i) => [k, b[i]]);

const FooterRefs = () => {
  return (
    <footer className="max-w-2xl mx-auto text-center mt-8 py-6">
      <div className="flex flex-wrap sm:flex-row flex-col justify-evenly">
        {footers.map((footer) => (
          <div className="w-full sm:w-1/4 px-2 mb-4" key={footer.title}>
            <h1 className="text-lg font-semibold text-gray-50 dark:text-gray-100 mb-2">
              {footer.title}
            </h1>
            <ul className="space-y-1">
              {zip(footer.descriptions, footer.links).map((item) => (
                <li key={item[0]}>
                  <LinkButton href={item[1]}>{item[0]}</LinkButton>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </footer>
  );
};

export const Footer = () => {
  return (
    <footer className="bg-extra-strong">
      <FooterRefs />
      <div className="items-center justify-between px-10 py-4 mx-auto sm:px-6 row max-w-7xl">
        <SocialLinks />
      </div>
      <div className="items-center justify-between px-10 py-4 mx-auto sm:px-6 row max-w-7xl">
        <Copyright />
      </div>
    </footer>
  );
};
