import { useState, useEffect } from "react";

import { LinkButton } from "../components/LinkButton";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { logout } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
function Resources() {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="text-light hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg text-sm p-1">
          Resources
        </MenuButton>
      </div>
      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-48 bg-gray-500  origin-top-right rounded-md shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <MenuItem>
            <span>
              <LinkButton
                href="https://blog.persius.org/"
                className="block px-4 py-2 text-sm hover:bg-gray-400"
              >
                {" "}
                Blog
              </LinkButton>
            </span>
          </MenuItem>
          <MenuItem>
            <span>
              <LinkButton
                href="/resources/downloads"
                className="block px-4 py-2 text-sm hover:bg-gray-400"
              >
                {" "}
                Digital Downloads
              </LinkButton>
            </span>
          </MenuItem>
          <MenuItem>
            <span>
              <LinkButton
                href="https://blog.persius.org/investigations"
                className="block px-4 py-2 text-sm hover:bg-gray-400"
              >
                {" "}
                Research
              </LinkButton>
            </span>
          </MenuItem>
          <MenuItem>
            <span>
              <LinkButton
                href="https://overturn-predictor.persius.org/"
                className="block px-4 py-2 text-sm hover:bg-gray-400"
              >
                {" "}
                Appeal Overturn Predictor
              </LinkButton>
            </span>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  );
}

function LoggedInResources() {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="text-light hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg text-sm p-1">
          Resources
        </MenuButton>
      </div>
      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-48 bg-gray-500  origin-top-right rounded-md shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <MenuItem>
            <span>
              <LinkButton
                href="/resources/self-help-tools"
                className="block px-4 py-2 text-sm hover:bg-gray-400"
              >
                Self Help Tools
              </LinkButton>
            </span>
          </MenuItem>
          <MenuItem>
            <span>
              <LinkButton
                href="/claims"
                className="block px-4 py-2 text-sm hover:bg-gray-400"
              >
                Appeal Support
              </LinkButton>
            </span>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  );
}

function AccountDropdown(props) {
  const navigate = useNavigate();
  const login_setter = props.loginSetter;

  const LogOut = () => {
    login_setter(false);
    logout();
    navigate("/");
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="text-light hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg text-sm p-1">
          Account
        </MenuButton>
      </div>
      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-48 bg-gray-500 origin-top-right rounded-md shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <MenuItem>
            <span>
              <LinkButton
                href="/account/profile"
                className="block px-4 py-2 text-sm hover:bg-gray-400"
              >
                Profile
              </LinkButton>
            </span>
          </MenuItem>
          <MenuItem>
            <span>
              <LinkButton
                href="/account/coverage"
                className="block px-4 py-2 text-sm hover:bg-gray-400"
              >
                Coverage
              </LinkButton>
            </span>
          </MenuItem>
          <MenuItem>
            <span>
              <button
                href="/"
                onClick={LogOut}
                className="block px-4 py-2 text-sm hover:bg-gray-400"
              >
                Logout
              </button>{" "}
            </span>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  );
}

const Navigation = () => (
  <nav>
    {" "}
    <ul className="items-center gap-1 sm:gap-2 row">
      {" "}
      <li>
        {" "}
        <LinkButton href="/about">Mission</LinkButton>{" "}
      </li>{" "}
      <li>
        {" "}
        <Resources />
        {/* <LinkButton href="/resources">Resources</LinkButton>{" "} */}
      </li>{" "}
      <li>
        {" "}
        <LinkButton href="/login">Login</LinkButton>{" "}
      </li>{" "}
    </ul>{" "}
  </nav>
);

function LoggedInNavigation(props) {
  const loginSetter = props.loginSetter;

  return (
    <nav>
      {" "}
      <ul className="items-center gap-1 sm:gap-2 row">
        <LinkButton href="/">Home</LinkButton>
        <li>
          <LoggedInResources />
        </li>
        <li>
          <AccountDropdown loginSetter={loginSetter} />
        </li>{" "}
      </ul>{" "}
    </nav>
  );
}

const Logo = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <a href="/">
      <div className="items-center block gap-1 row">
        {" "}
        {isMobile && (
          <img
            src="/images/persius_f1_maroon.webp"
            alt="Persius Name"
            height="30"
            width="75"
          />
        )}
        {!isMobile && (
          <>
            <img
              src="/images/logo.webp"
              alt="Persius logo"
              height="40"
              width="40"
            />
            &nbsp;
            <img
              src="/images/persius_f1_maroon.webp"
              alt="Persius Name"
              height="50"
              width="220"
            />
          </>
        )}
      </div>
    </a>
  );
};

export function Header(props) {
  const [top, setTop] = useState(false);

  const handleScroll = () => {
    setTop(window.scrollY <= 10);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // The empty dependency array ensures this runs only once when the component mounts

  // State
  const isLoggedIn = props.isLoggedIn;
  let setIsLoggedIn = props.setIsLoggedIn;

  if (!isLoggedIn) {
    return (
      // Colors must be set explicitly since opacity and blur don't work together
      <header
        className={`fixed w-full z-30 transition duration-300 ${
          !top && "bg-gray-50/90 dark:bg-gray-900/90 backdrop-blur-sm shadow-lg"
        }`}
      >
        {/* Header Content */}
        <div className="items-center justify-between h-16 px-5 mx-auto row md:h-20 max-w-7xl sm:px-6">
          <Logo />
          <Navigation />
        </div>
      </header>
    );
  } else {
    return (
      // Colors must be set explicitly since opacity and blur don't work together
      <header
        className={`fixed w-full z-30 duration-300 dark:bg-gray-900 backdrop-blur-sm shadow-lg"`}
      >
        {/* Header Content */}
        <div className="items-center justify-between h-16 px-5 mx-auto row md:h-20 max-w-7xl sm:px-6">
          <Logo />
          <LoggedInNavigation loginSetter={setIsLoggedIn} />
        </div>
      </header>
    );
  }
}
