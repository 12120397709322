//External Imports
import Box from "@mui/material/Box";
import { Footer } from "../components/sections/Footer.jsx";

export default function StickyFooter({ isLoggedIn }) {
  return (
    <Box
      component="footer"
      className="bg-extra-strong"
      // sx={{
      //   position: "relative"
      // }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // minHeight: '100vh',
      }}
    >
      {!isLoggedIn && <Footer />}
    </Box>
  );
}
