import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs as DateAdapter } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";

export default function IssuePage({ updateFormData, formData }) {
  const updateDate = (e) => {
    updateFormData("care_date", "value")(e);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" mb={3} gutterBottom>
        Issue Questionnaire
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          Is your health insurance sponsored by your employer?
          <RadioGroup
            row
            aria-label="employer_sponsored"
            defaultValue={formData.employer_sponsored}
            value={formData.employerSponsored}
            onChange={updateFormData("employer_sponsored")}
            name="employer-sponsored-radio-buttons-group"
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} md={12}>
          Is a resolution urgent?
          <RadioGroup
            row
            aria-label="resolution_urgent"
            defaultValue={formData.urgent}
            value={formData.urgent}
            onChange={updateFormData("urgent")}
            name="resolution-urgent-radio-buttons-group"
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} md={12}>
          When did you receive care? <br />
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              value={formData.care_date}
              onChange={updateDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={12}>
          Did you submit a claim that was denied?
          <RadioGroup
            row
            aria-label="claim_denied"
            defaultValue={formData.initially_denied}
            value={formData.initially_denied}
            onChange={updateFormData("initially_denied")}
            name="claim-denied-radio-buttons-group"
          >
            <FormControlLabel value="YES" control={<Radio />} label="Yes" />
            <FormControlLabel value="NO" control={<Radio />} label="No" />
            <FormControlLabel
              value="not_applicable"
              control={<Radio />}
              label="Not Applicable"
            />
          </RadioGroup>
        </Grid>

        {formData.initially_denied === "YES" && (
          <Grid item xs={12} md={12}>
            What was the reason given for the denial?
            <RadioGroup
              aria-label="denial_reason"
              defaultValue={formData.denial_reason}
              value={formData.denial_reason}
              onChange={updateFormData("denial_reason")}
              name="denial-reason-radio-buttons-group"
            >
              <FormControlLabel
                value="NMN"
                control={<Radio />}
                label="Not Medically Necesssary"
              />
              <FormControlLabel
                value="OON"
                control={<Radio />}
                label="Out of Network"
              />
              <FormControlLabel
                value="Experimental"
                control={<Radio />}
                label="Experimental Treatment"
              />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="Other"
              />
              <FormControlLabel
                value="Unknown"
                control={<Radio />}
                label="Unknown"
              />
            </RadioGroup>
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          Have you filed an internal appeal with your insurer?
          <RadioGroup
            row
            aria-label="intenal_appeal"
            defaultValue={formData.internally_appealed}
            value={formData.internally_appealed}
            onChange={updateFormData("internally_appealed")}
            name="internal-appeal-radio-buttons-group"
          >
            <FormControlLabel value="YES" control={<Radio />} label="Yes" />
            <FormControlLabel value="NO" control={<Radio />} label="No" />
            <FormControlLabel
              value="not_applicable"
              control={<Radio />}
              label="Not Applicable"
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} md={12}>
          Have you filed an external appeal with a third party?
          <RadioGroup
            row
            aria-label="externally_appealed"
            defaultValue={formData.externally_appealed}
            value={formData.externally_appealed}
            onChange={updateFormData("externally_appealed")}
            name="external-appeal-radio-buttons-group"
          >
            <FormControlLabel value="YES" control={<Radio />} label="Yes" />
            <FormControlLabel value="NO" control={<Radio />} label="No" />
            <FormControlLabel
              value="not_applicable"
              control={<Radio />}
              label="Not Applicable"
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
