import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Fab from "@mui/material/Fab";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";

//Internal Imports
import { getSupportMessages, submitSupportMessage } from "../api/claims.ts";
import { handle401, getToken } from "../utils/auth.ts";
import { SOCKET_URL } from "../utils/config";
import UserAvatar, { stringToColor } from "./account/UserAvatar";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const style = {
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "80vh",
    border: "1px solid #e0e0e0",
    boxShadow: "none",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
    borderBottom: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "60vh",
    overflowY: "auto",
    borderBottom: "1px solid #e0e0e0",
  },
};

const Chat = (props) => {
  const claimUid = props.uid;
  const currUser = props.user;
  const socket = props.socket;
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [validationError, setValidationError] = useState({
    field: "",
    message: "",
  });

  const [messages, setMessages] = useState([]);

  function refreshMessages() {
    getSupportMessages(claimUid)
      .then((result) => {
        setMessages(result.data.messages);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handle401();
        } else {
          setError(error);
        }
      });
  }

  useEffect(refreshMessages, [claimUid]);

  const handleChange = (event) => {
    setError(null);
    setValidationError({
      field: "",
      message: "",
    });
    if (event.target.value === "\n") {
      setMessage("");
    } else {
      setMessage(event.target.value);
    }
  };

  let users = messages.map(function (item) {
    return { username: item.username, role: item.role };
  });
  let allUsers = [...new Set(users.map(JSON.stringify))];
  let uniqueUsers = allUsers.map(JSON.parse);

  function isCurrUser(name) {
    return name === currUser.username;
  }

  // function currUserRole(user) {
  //   if (user.is_superuser) {
  //     return "staff";
  //   } else if (user.is_staff) {
  //     return "staff";
  //   } else {
  //     return "community";
  //   }
  // }

  function side(name) {
    if (isCurrUser(name)) {
      return "right";
    } else {
      return "left";
    }
  }

  function checkValid(message) {
    if (message.length === 0) {
      setValidationError({
        field: "message",
        message: "Please enter a message.",
      });
    } else {
      return true;
    }
    return false;
  }

  function submitMessage() {
    let valid = checkValid(message);
    if (valid) {
      submitSupportMessage({ content: message, claim_uid: claimUid })
        .then((result) => {
          let new_message = result;
          let message_json = {
            content: new_message.content,
            username: new_message.username,
            role: new_message.role,
            time: new_message.time,
            uid: new_message.uid,
          };
          socket.send(JSON.stringify(message_json));
          refreshMessages();
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            handle401();
          } else {
            setError("Request Error");
          }
        });
      setMessage("");
    }
  }

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      submitMessage();
    }
  };

  // Render new messages from broadcasts on websocket
  // If not already populated
  useEffect(() => {
    socket.onmessage = function (event) {
      var data = JSON.parse(event.data);
      let existing_uids = messages.map((elt) => elt.uid);
      if (!existing_uids.includes(data.uid)) {
        setMessages([...messages, data]);
      }
    };
  }, [socket, messages]);

  if (!error) {
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" className="header-message" mb={5}>
              Support For Claim {claimUid.slice(0, 8)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container component={Paper} sx={style.chatSection}>
          <Grid item xs={12} md={4} sx={style.borderRight500}>
            <Typography align="center" variant="h5" mb={2} mt={2}>
              People
            </Typography>
            <Divider />
            <List>
              {uniqueUsers.map((user) => {
                return (
                  <ListItem key={user.username}>
                    <Stack spacing={2} direction="row">
                      <UserAvatar
                        alt={user.username}
                        username={user.username}
                      />
                      <ListItemText primary={user.username}>
                        {user.username}
                      </ListItemText>
                      <Chip
                        label={capitalizeFirstLetter(user.role)}
                        color={
                          (capitalizeFirstLetter(user.role) === "Community" &&
                            "secondary") ||
                          (capitalizeFirstLetter(user.role) === "Staff" &&
                            "success") ||
                          (capitalizeFirstLetter(user.role) ===
                            "Claims Admin" &&
                            "success") ||
                          (capitalizeFirstLetter(user.role) === "Superuser" &&
                            "success") ||
                          "error"
                        }
                      />
                    </Stack>
                  </ListItem>
                );
              })}
            </List>
            <Divider />
          </Grid>
          <Grid item xs={12} md={8}>
            <List sx={style.messageArea}>
              {messages.map((message) => {
                // Add alpha 20% component:
                const transparentColor = stringToColor(message.username) + "33";
                return (
                  <ListItem key={message.uid}>
                    <Grid container>
                      <Card
                        mb={1}
                        mt={1}
                        style={{ padding: "20px" }}
                        sx={{
                          backgroundColor: transparentColor,
                        }}
                      >
                        <Grid item xs={12}>
                          <ListItemText
                            align={side(message.username)}
                            primary={message.content}
                          ></ListItemText>
                        </Grid>
                        <Grid item xs={12}>
                          <ListItemText
                            align={side(message.username)}
                            secondary={message.username + "@" + message.time}
                          ></ListItemText>
                        </Grid>
                      </Card>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Divider />
          <Grid container mt={2} mb={4} justifyContent="flex-end">
            <Grid item xs={10} md={6}>
              <TextField
                id="message"
                value={message}
                multiline
                rows={3}
                label="Message"
                fullWidth
                onChange={handleChange}
                error={validationError.field === "message"}
                helperText={
                  validationError.field === "message" && validationError.message
                }
                onKeyPress={(e) => {
                  handleEnter(e);
                }}
              />
            </Grid>
            <Grid item mt={2} mx={2} xs={1} md={1} align="right">
              <Fab
                color="primary"
                type="submit"
                aria-label="add"
                onClick={submitMessage}
              >
                <SendIcon />
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <Card>
        {" "}
        <Alert severity="error">{error}</Alert>
      </Card>
    );
  }
};

export function ModalBase(props) {
  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("md")]: { width: "96%" },
    [theme.breakpoints.up("md")]: { width: "80%" },
    bgcolor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: 12,
    overflow: "scroll",
    p: 4,
    color: theme.palette.text.primary,
  };
  let closeAction = props.closeAction;
  let openAction = props.openAction;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    if (openAction) {
      openAction();
    }
  };
  const handleClose = () => {
    setOpen(false);
    if (closeAction) {
      closeAction();
    }
  };

  return (
    <div>
      <Button
        startIcon={props.startIcon}
        variant={props.buttonVariant}
        onClick={handleOpen}
      >
        {props.buttonText}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{props.children}</Box>
      </Modal>
    </div>
  );
}

export function ChatModal(props) {
  let buttonVariant = "text";
  let uid = props.uid;
  let user = props.user;
  const [socket, setSocket] = React.useState(null);
  return (
    <ModalBase
      buttonText="Message Support"
      buttonVariant={buttonVariant}
      openAction={() => {
        setSocket(
          new WebSocket(
            SOCKET_URL + "?user_token=" + getToken() + "&claim_uid=" + uid
          )
        );
      }}
      closeAction={() => {
        socket.close();
        setSocket(null);
      }}
    >
      <Chat uid={uid} user={user} socket={socket} />
    </ModalBase>
  );
}

export default ChatModal;
