import { ScatteredSpheres } from "../svg/ScatteredSpheres";
import { Title } from "../components/Title";
import { Details } from "../components/Details";
import { DemoImage } from "../components/Demo";
import { Section } from "../components/Section";
import { GradientText } from "../components/GradientText";

const Background = () => (
  <div
    className="absolute inset-0 translate-y-32 pointer-events-none dark:invert dark:brightness-90"
    aria-hidden="true"
  >
    <ScatteredSpheres />
  </div>
);

export const MissionHero = () => {
  return (
    <Section
      gradients
      className="items-center justify-center min-h-screen 2xl:min-h-[1000px] h-fit gap-16 col md:flex-row"
    >
      <Background />
      {/* Text */}
      <div className="z-10 gap-4 text-center col md:text-left">
        <Title size="lg">
          <GradientText className="from-persius_blue-600 to-persius_green-600">
            What We&apos;re About
          </GradientText>
        </Title>
        <Details>
          Our mission is to advocate for patients, seek justice, and improve
          systems in U.S. healthcare.
        </Details>
      </div>
      {/* Image */}
      <DemoImage
        imgSrc="/images/volunteerWheelchair.webp"
        alt="A volunteer helps a patient in a wheelchair."
        height={800}
        width={800}
      />
    </Section>
  );
};
