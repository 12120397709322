//External Imports
import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

//Internal Imports
import App from "./App";
import ClaimForm from "./components/claims/ClaimForm";
import CoverageHelp from "./components/CoverageHelp";
import CoverageHelpRegionInfo from "./components/CoverageHelpRegionInfo";
import { PrivateRoute } from "./components/PrivateRoute";
import AccountHome from "./views/AccountHome";
import Contact from "./views/Contact";
import Coverage from "./views/Coverage";
import Downloads from "./views/Downloads";
import HomePage from "./views/Homepage";
import Login from "./views/Login";
import Mission from "./views/Mission";
import PageNotFound from "./views/PageNotFound";
import Profile from "./views/Profile";
import Signup from "./views/Signup";
import VerifyEmail from "./views/VerifyEmail";
import ViewClaims from "./views/ViewClaims";
import Terms from "./views/Terms";
import PrivacyPolicy from "./views/PrivacyPolicy";
import { isAuthenticated } from "./utils/auth.ts";

// Code Splitting via route imports
// TODO: Figure out why code splitting was breaking menu logic
// const App = lazy(() => import("./App"));
// const ClaimForm = lazy(() => import("./components/claims/ClaimForm"));
// const CoverageHelp = lazy(() => import("./components/CoverageHelp"));
// const CoverageHelpRegionInfo = lazy(() =>
//   import("./components/CoverageHelpRegionInfo")
// );
// const AccountHome = lazy(() => import("./views/AccountHome"));
// const Contact = lazy(() => import("./views/Contact"));
// const Coverage = lazy(() => import("./views/Coverage"));
// const HomePage = lazy(() => import("./views/Homepage"));
// const Login = lazy(() => import("./views/Login"));
// const Mission = lazy(() => import("./views/Mission"));
// const PageNotFound = lazy(() => import("./views/PageNotFound"));
// const Profile = lazy(() => import("./views/Profile"));
// const Services = lazy(() => import("./views/Services"));
// const Signup = lazy(() => import("./views/Signup"));
// const Us = lazy(() => import("./views/Us"));
// const ViewClaims = lazy(() => import("./views/ViewClaims"));
// const Terms = lazy(() => import("./views/Terms"));
// const PrivacyPolicy = lazy(() => import("./views/PrivacyPolicy"));

export const RouteLayout = () => {
  let authRoles = isAuthenticated();
  let [isLoggedIn, setIsLoggedIn] = useState(authRoles);

  return (
    <Routes>
      <Route
        path="/"
        element={<App isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />}>
        {/* Home Page */}
        <Route
          exact
          path="/"
          element={
            (!isLoggedIn && <HomePage />) || (isLoggedIn && <AccountHome />)
          }
        />

        {/* Login */}
        <Route
          exact
          path="login"
          element={
            <Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
          }
        />

        {/* Sign Up */}
        <Route
          exact
          path="signup"
          element={
            <Signup isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
          }
        />

        {/* Email Verification */}
        <Route exact path="verify-email" element={<VerifyEmail />} />

        {/* About Routes */}
        <Route exact path="about" element={<Mission />} />

        {/* Company Routes */}
        <Route exact path="contact" element={<Contact />} />
        <Route exact path="privacy" element={<PrivacyPolicy />} />
        <Route exact path="terms" element={<Terms />} />

        {/* Account Specific Routes */}
        <Route element={<PrivateRoute isLoggedIn={isLoggedIn} />}>
          <Route exact path="account/profile" element={<Profile />} />
        </Route>
        <Route element={<PrivateRoute isLoggedIn={isLoggedIn} />}>
          <Route exact path="account/coverage" element={<Coverage />} />
        </Route>

        {/* Resource Routes */}
        <Route exact path="resources/downloads" element={<Downloads />} />
        <Route element={<PrivateRoute isLoggedIn={isLoggedIn} />}>
          <Route
            exact
            path="resources/self-help-tools"
            element={<CoverageHelp />}
          />
        </Route>
        <Route element={<PrivateRoute isLoggedIn={isLoggedIn} />}>
          <Route
            path="resources/self-help-tools/:region"
            element={<CoverageHelpRegionInfo />}
          />
        </Route>

        {/*  Claims Routes */}
        <Route element={<PrivateRoute isLoggedIn={isLoggedIn} />}>
          <Route path="/claims" element={<ViewClaims />} />
        </Route>
        <Route element={<PrivateRoute isLoggedIn={isLoggedIn} />}>
          <Route path="/claims/submit-claim" element={<ClaimForm />} />
        </Route>

        {/* Catch Alls */}
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Route>
    </Routes>
  );
};
